import useDocumentTitle from "../components/documentTile";
import "../css/Academic.scss"
import "../css/home.scss"
import DropDownInput from "../components/dropdowninput";
import {useEffect, useState} from "react";
import FirestoreData from "../components/firebase";
import {toast} from "react-toastify";
import MaterialList from "../components/materialList";
import InstaTopBar from "../css/InstaTopBar";
import FormLink from "../components/formLink";

const materialType = ["Assignments", "Books", "Notes", "Youtube Playlists", "Previous Year Papers"]

export default function Academic() {
    const queryParams = new URLSearchParams(window.location.search)
    const course = queryParams.get("course")
    const semester = queryParams.get("semester")
    const subject = queryParams.get("subject")
    const subjectType = queryParams.get("subjectType")
    useDocumentTitle(`${course} ${semester} ${subject}`)

    const [materialsComponent, setSubjectsComponent] = useState(<></>);
    const database = new FirestoreData()

    async function loadButton() {
        if (document.getElementById('material').value !== "Select Material"
        ) {
            let materialsList = []
            const material = document.getElementById('material').value
            if (subjectType === "Core" ) {
                materialsList = await database.getCoreMaterialData(material, semester, course, subject)
            }else if(subjectType === "AECC/SEC"){
                materialsList = await database.getNonCoreMaterialData(material, semester, subject, "AECC")
            }else {
                materialsList = await database.getNonCoreMaterialData(material, semester, subject, "GE")
            }
            if (materialsList.length !== 0) {
                setSubjectsComponent(<MaterialList materials={materialsList} name={material}/>
                );
            }else{
                setSubjectsComponent(<p className="subjects-text">tch tch tch, kuch nhi hai lol</p>)
            }
        } else {
            toast("(-_-) Select everything")
        }
    }

    return (
        <div className="home">
            <InstaTopBar/>
            <div className="dropdown-div1">
                <DropDownInput dropdownid="material" name={"Select Material"} optionsList={materialType}/>
                <button onClick={loadButton} className={"load-button hover-effect"}>Load Data</button>
                {materialsComponent}
            </div>
            <FormLink/>
        </div>
    )
}