import "../css/home.scss"

export default function MaterialList(props) {

    if (props.name !=="") {
        return (
            <div>
                <p className="subjects-text">
                    {props.name}
                </p>
                <p className="subjects-text rotate">
                    =>
                </p>
                {props.materials.map((subject) => (
                    <button onClick={() => {
                        window.open(subject["link"], "_blank")
                    }} className="datalist-button hover-effect" key={subject["name"]}>
                        {subject["name"]}
                    </button>
                ))}
            </div>
        );
    } else {
        return (<></>)
    }
}

