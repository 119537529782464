import "../css/home.scss"
import { useNavigate } from 'react-router-dom';

export default function SubjectList(props) {
    const navigate = useNavigate()
    let academicNavigate = (button_subject) => {
        navigate(`/academic?course=${document.getElementById('course').value}&semester=${document.getElementById('semester').value}&subject=${button_subject}&subjectType=${document.getElementById('subjectType').value}`)
    }
    return (
        <div>
            <p className="subjects-text">
                Subjects
            </p>
            <p className="subjects-text rotate">
                =>
            </p>
            {props.subjects.map((subject) => (
                <button onClick={() =>{academicNavigate(subject)}} className="datalist-button hover-effect" id={subject} key={subject}>
                    {subject}
                </button>
            ))}
        </div>
    );
}