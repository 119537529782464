import {initializeApp} from "firebase/app";
import React, {Component} from 'react';
import {collection, getDocs, getFirestore, doc, query, where} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCB3hVLEDpMmba_fB1vV-ns3olkQPEpa5o",
    authDomain: "educircle-dduc.firebaseapp.com",
    projectId: "educircle-dduc",
    storageBucket: "educircle-dduc.appspot.com",
    messagingSenderId: "840268544154",
    appId: "1:840268544154:web:40a3b52a5872401bac8723",
    measurementId: "G-9XKB7217EE"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function collectionDocuments(firestoreRef, collectionName, equalToName) {
    const collectionQuery = query(collection(firestoreRef, collectionName), where("name", "==", equalToName))
    const docSnapShot = await getDocs(collectionQuery);
    return docSnapShot.docs
}

export default class FirestoreData extends Component {
    constructor(props) {
        super(props);
    }

    getCoreSubjects = async (course, semester) => {
        let subjectsList = []
        const courseRef = await collectionDocuments(db, "Courses", course)
        courseRef.map((courseData) => {
                for (const courseDataKey in courseData.data()[`sem${semester}`]) {
                    if (courseData.data()[`sem${semester}`][courseDataKey] !== "") {
                        subjectsList.push(courseData.data()[`sem${semester}`][courseDataKey])
                    }
                }
            }
        )
        return subjectsList
    }

    getNonCoreSubjects = async (material, semester, AECCOrGE) => {
        let materialDataList = [];
        const materialRef = await collectionDocuments(db, 'MaterialType', material)
        for (const materialData of materialRef) {
            if (AECCOrGE) {
                if (parseInt(semester) % 2 === 0) {
                    semester--;
                }
            }
            const semesterRef = await collectionDocuments(materialData.ref, "semester", "sem" + semester)
            for (const semesterData of semesterRef) {
                if (AECCOrGE) {
                    const aeccSubjectRef = collection(semesterData.ref, "AECC")
                    const aeccSubjectSnapshot = await getDocs(aeccSubjectRef)
                    aeccSubjectSnapshot.docs.map((subjectData) => {
                        if (subjectData.data()["name"] !== "none") {
                            materialDataList.push(subjectData.data()["name"])
                        }
                    })
                } else {
                    const geSubjectRef = collection(semesterData.ref, "GE")
                    const geSubjectSnapshot = await getDocs(geSubjectRef)
                    geSubjectSnapshot.docs.map((subjectData) => {
                        if (subjectData.data()["name"] !== "none") {
                            materialDataList.push(subjectData.data()["name"])
                        }
                    })
                }
            }
        }
        return materialDataList;
    }

    getCoreMaterialData = async (material, semester, course, subject) => {
        let materialDataList = [];
        const materialRef = await collectionDocuments(db, 'MaterialType', material)
        for (const materialData of materialRef) {
            const semesterRef = await collectionDocuments(materialData.ref, "semester", "sem" + semester)
            for (const semesterData of semesterRef) {
                const courseRef = await collectionDocuments(semesterData.ref, "course", course)
                for (const courseData of courseRef) {
                    const subjectRef = await collectionDocuments(courseData.ref, "subject", subject)
                    for (const subjectData of subjectRef) {
                        const materialRef = collection(subjectData.ref, "material")
                        const materialSnapshot = await getDocs(materialRef)
                        materialSnapshot.docs.map((materialData) => {
                            if (materialData.data()["name"] !== "none") {
                                materialDataList.push(materialData.data())
                            }
                        })
                    }
                }
            }
        }

        return materialDataList
    }

    getNonCoreMaterialData = async (material, semester, subject, subjectType) => {
        let materialDataList = [];
        const materialRef = await collectionDocuments(db, 'MaterialType', material)
        for (const materialData of materialRef) {
            const semesterRef = await collectionDocuments(materialData.ref, "semester", "sem" + semester)
            for (const semesterData of semesterRef) {
                const courseRef = await collectionDocuments(semesterData.ref, subjectType, subject)
                for (const courseData of courseRef) {
                    const materialRef = collection(courseData.ref, "material")
                    const materialSnapshot = await getDocs(materialRef)
                    materialSnapshot.docs.map((materialData) => {
                        if (materialData.data()["name"] !== "none") {
                            materialDataList.push(materialData.data())
                        }
                    })

                }
            }
        }

        return materialDataList
    }
}
