import "../css/home.scss"
import DropDownInput from "../components/dropdowninput";
import {useState} from "react";
import FirestoreData from "../components/firebase";
import SubjectList from "../components/subjectList";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useDocumentTitle from "../components/documentTile";
import InstaTopBar from "../css/InstaTopBar";
import FormLink from "../components/formLink";
import {length} from "tailwindcss/lib/util/dataTypes";

toast.configure()

const courses = [
    "B.Sc. (H) Electronics",
    "B.Sc. (H) Computer Science",
    "B.Sc. (H) Physics",
    "B.Sc. (H) Mathematics",
    "B.Sc. (H) Chemistry",
    "B.Sc. Mathematical Science",
    "B.Sc. (H) Zoology",
    "B.Sc. Life Sciences",
    "B.Sc. Physical Sciences (CS)",
    "B.Sc. Physical Sciences (Chemistry)",
    "B.Com (H)",
    "B.A. (H) English",
    "Bachelor in Management Studies",
    "B.A. Programme"
]

// document.getElementById('course').value

export default function Home() {
    useDocumentTitle("Duline")
    const [subjectsComponent, setSubjectsComponent] = useState(<></>);
    const database = new FirestoreData()

    let loadButton = async () => {
        if (document.getElementById('course').value !== "Select Course" &&
            document.getElementById('semester').value !== "Select Semester" &&
            document.getElementById('subjectType').value !== "Select Subject Type"
        ) {
            let subjectList = [];
            if(document.getElementById('subjectType').value === "Core"){
                subjectList = await database.getCoreSubjects(document.getElementById('course').value,
                document.getElementById('semester').value);
            }else if(document.getElementById('subjectType').value === "GE"){
                subjectList = await database.getNonCoreSubjects("Books", document.getElementById('semester').value, false)
            }else if(document.getElementById('subjectType').value === "AECC/SEC"){
                subjectList = await database.getNonCoreSubjects("Books", document.getElementById('semester').value, true)
            }

            if (subjectList.length !== 0 ){
                setSubjectsComponent(
                    <SubjectList subjects={subjectList}/>
                )
            }else {
                setSubjectsComponent(
                    <p className="subjects-text">Kuch nhi hai ಠ︵ಠ</p>
                )
            }
        }else{
            toast("(-_-) Select everything")
        }
    }

    return (
        <div className={"home"}>
            <InstaTopBar/>
            <div className={"dropdown-div1"}>
            <DropDownInput dropdownid="course" name={"Select Course"} optionsList={courses}/>
            <DropDownInput dropdownid="semester" name={"Select Semester"} optionsList={["1", "2", "3", "4", "5", "6"]}/>
            <DropDownInput dropdownid="subjectType" name={"Select Subject Type"} optionsList={["Core", "GE", "AECC/SEC"]}/>
            <button onClick={loadButton} className={"load-button hover-effect"}>Load Subjects</button>
            {subjectsComponent}
        </div>
            <FormLink/>
        </div>
    );
}