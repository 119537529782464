import './css/App.css';
import {Routes, Route} from 'react-router-dom';
import Home from "./pages/home";
import Academic from "./pages/Academic";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home/>}>
        </Route>
        <Route path="/academic" element={<Academic/>}>
        </Route>
      </Routes>
  );
}

export default App;
