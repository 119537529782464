import "../css/home.scss"

export default function InstaTopBar(){
    return (
        <div style={{display: "flex"}}>
            <div style={{flexGrow: 1}}/>
            <div className={"insta-handle-bar"}>
                <a href="https://www.instagram.com/ya._.shh/" target="_blank" >
                <img src={"assets/insta.svg"} alt="insta icon" width={"40 vh"}/>
                </a>
                <a className={"insta-username"} href="https://www.instagram.com/ya._.shh/" target="_blank" >@ya._.shh</a>
                <p className={"insta-follow-krlena"}>Follow krlena</p>
            </div>
        </div>

    );
}