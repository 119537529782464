import "../css/home.scss"

export default function FormLink(){
    return(
        <div className="form-link">
            <button onClick={()=>{
                window.open("https://forms.gle/6QVNceay95ciciZH9", "_blank")
            }} className={"load-button hover-effect"}>Add Material</button>
        </div>
    );
}