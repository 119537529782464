import "../css/home.scss"

export default function DropDownInput(props) {

    return (
        <div className={"dropdown-div2"}>
            <select className="dropdown-select hover-effect" id={props.dropdownid} defaultValue={props.name}>
                <option disabled>{props.name}</option>
                {props.optionsList.map((data) => (
                    <option value={data} className={"dropdown-options"} key={data}>{data}</option>
                ))}
            </select>
        </div>
    );
};